import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { createWorker } from '../redux/features/workerSlice';
import Workers from '../lists/Workers';
const initialState = {
  firstName:'',
  lastName:''
}
const AddWorker = ({goToAddSession, workers})=> {
  const [workerData, setWorkerData] = useState(initialState);
  const {firstName, lastName} = workerData;
  const dispatch  = useDispatch();
  const navigate = useNavigate();
 const onInputChange = (e)=>{
  const {name, value} = e.target;
        setWorkerData({...workerData, [name]: value})
 };
 const handleSubmit = (e) =>{
  e.preventDefault();
    dispatch(createWorker({workerData, navigate, toast}));
    goToAddSession();
 }
  return (
<div>
        <h2 className="text-xl font-bold text-white mb-1">Add Worker</h2>
        <form onSubmit={handleSubmit} className="space-y-6 w-full" noValidate>
        <div className="flex md:flex-row flex-col w-full">
            <div className='md:w-1/2 w-full'>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-100">First Name</label>
            <input onChange={onInputChange} id="firstName" name="firstName" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          <div className='md:w-1/2 w-full'>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-100">Last Name</label>
            <input onChange={onInputChange} id="lastName" name="lastName" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
            </div>
          <button type="submit" className="w-full flex justify-center py-2 px-2 bor1er border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
            Submit
          </button>
        </form><h5 className='text-white font-bold py-1'>All Workers</h5>
       <Workers workers={workers} />
      </div>
  )
}

export default AddWorker;