import React, { useState } from "react";

const PriceInput = ({ sendPrice, oldPrice }) => {
  const [price, setPrice] = useState("");

  const formatPrice = (value) => {
    const numericValue = value.replace(/[^0-9,]/g, ""); // Čuva samo brojeve i zarez
    const parts = numericValue.split(",");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Dodaje tačke kao razdvajanje
    if (parts[1] === undefined) {
      return integerPart; // Samo ceo deo
    } else {
      return `${integerPart},${parts[1].slice(0, 2)}`; // Dodaje decimalni deo
    }
  };

  const onInputChange = (e) => {
    const inputElement = e.target;
    const rawValue = inputElement.value;
    const cursorPosition = inputElement.selectionStart;

    // Broji koliko se znakova promenilo
    const rawValueBeforeFormat = rawValue.replace(/[^0-9,]/g, ""); 
    const formattedPrice = formatPrice(rawValue);
    setPrice(formattedPrice);

    // Prilagođava poziciju kursora
    const cursorAdjustment =
      formattedPrice.length - rawValueBeforeFormat.length;
    const newCursorPosition = cursorPosition + cursorAdjustment;

    // Šalje podatke u parent komponentu
    const numericPrice = parseFloat(
      formattedPrice.replace(/\./g, "").replace(",", ".")
    ) || 0;
    sendPrice(numericPrice);

    // Vraća kursor na odgovarajuće mesto
    setTimeout(() => {
      inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const formattedOldPrice = oldPrice
    ? formatPrice(oldPrice.toString().replace(".", ","))
    : "0,00";

  return (
    <div>
      <input
        value={price}
        onChange={onInputChange}
        id="price"
        name="price"
        type="text"
        inputMode="decimal"
        placeholder={formattedOldPrice}
        className="w-full px-2 py-1 text-lg border border-gray-300 rounded-lg shadow-md focus:ring-4 focus:ring-green-500 focus:border-green-500 focus:outline-none transition-all duration-300 ease-in-out placeholder-gray-400"
      />
    </div>
  );
};

export default PriceInput;
