import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faEdit,faTimes } from '@fortawesome/free-solid-svg-icons';
import { selectUser } from '../redux/features/authSlice';

const WorkerData = ({workerData, closePopup}) =>  {
    const user = useSelector(selectUser);
    const formattedDate = new Date(workerData.createdAt).toLocaleDateString('sr-RS', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div 
        className="bg-white rounded-sm shadow-lg p-6 w-11/12 md:w-1/3"
        onClick={(e) => e.stopPropagation()} // Sprečava zatvaranje kada se klikne unutar popupa
      >
        <h2 className="text-xl font-bold mb-4">All Worker Data</h2>
        <div className="min-w-full bg-transparent rounded-sm shadow-lg bg-gray-50">
        <ul className="border-b border-transparent last:border-none">
        <li className="px-2 py-1 text-black">First Name : <b>{workerData.firstName}</b> </li><hr/>
        <li className="px-2 py-1 text-black">Last Name : <b>{workerData.lastName}</b> </li><hr/>
        <li className="px-2 py-1 text-black"><i>Created <b className='text-sm'>{formattedDate}</b> by  <b className='text-sm'>{user?.result?.email}</b></i></li><hr/>
        </ul>
        </div>
     
        <div className='flex justify-end'>
        <button 
          onClick={closePopup} 
          className="mt-4 px-2 py-1 bg-black text-white rounded-sm"
        >
        <FontAwesomeIcon className='p-2' icon={faTimes} />

        </button>
        </div>
     
       
      </div>
    </div>
    )
  }

export default WorkerData