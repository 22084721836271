import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../redux/features/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { createSMS, getSMS, sendSMSMessage} from '../redux/features/smsSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import {toast} from 'react-toastify';
import AddMessage from './AddMessage';
import KindSending from './KindSending';


const SendSMS = ({data}) => {
  const user = useSelector(selectUser);
  const loading = useSelector((state) => state.client.loading);
  const clients = useSelector((state) => state.client.clients);
  const userId = user?.result._id;
  const [campain, setCampain] = useState(false);
  const [kind, setKind] = useState();
  const [operator, setOperator] = useState('');
  const [messageBox, setMessageBox] = useState(false);
  const [listCamp, setListCamp] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

useEffect(() => {
 dispatch(getSMS({userId}));
}, [])
const sms = useSelector((state) => state.sms.sms);
const setMessage = (val)=>{
  if(val === 'camp'){
    setListCamp(true)
  }else{
    setListCamp(false)
  }
  setMessageBox(true);
  setKind(val);
}
const sendSMS = (message, operator) =>{
console.log(userId);
alert(`Messages are in prepering`)

  // alert(`The number of users to whom the messages are being sent is  ${clients.length}, which would cost you a total of  ${clients.length*3} rsd, via ${operator} provider`)
 if(operator === 'sms'){
 //dispatch(sendSMSMessage({message, userId, toast, navigate}))
}
if(operator === 'viber'){
  console.log('jeste viber');
   // dispatch(sendSMSMessage({message, toast}))}
  }
  if(operator === 'whatsup'){
    console.log('jeste whatsup');
     // dispatch(sendSMSMessage({message, toast}))}
    }
    if(operator === 'wechat'){
      console.log('jeste wechat');
       // dispatch(sendSMSMessage({message, toast}))}
      }
}
const handleDelete = () =>{}
const takeOperator =(par)=>{
setOperator(par)
}
  return (   
     <div className="sms-box">
       <h2 className="text-xl font-bold mb-4 text-white">Create notifications with:</h2>
       <div className="flex flex-row">
       <button onClick={()=>setMessage('auto')}
      className="text-center bg-black ml-1 text-white font-bold w-full py-2 shadow-md"
    >
      Automatic <br/>  message
    </button>
    <button  onClick={()=>setMessage('camp')} 
      className="text-center bg-black mx-1 text-white font-bold w-full py-2 shadow-md"
    >
      Campains <br /> message
    </button>
       </div>
    {messageBox && <AddMessage  closePopup={()=>setMessageBox(false)} kind={kind} />} 
    <h4 className='text-white p-2 font-bold'>List of created messages:</h4>
    <div className="flex flex-row">
    <button onClick={()=>setListCamp(false)}
      className={`text-xs bg-black ml-1 text-white font-bold w-full py-2 shadow-md ${!listCamp?'font-bold':'font-normal'}`}
    >
      Automatic <br/>  message
    </button>
    <button   onClick={()=>setListCamp(true)} 
      className={`text-xs bg-black mx-1 text-white  w-full py-2 shadow-md  ${listCamp?'font-bold':'font-normal'}`}
    >
      Campains <br /> message
    </button></div>
    <div>
      
    {!listCamp && sms && sms
  .filter((sms)=>sms.kind === 'auto')
  .map((smsPrint, index) =>{
      return(
      <div className='w-full flex flex-col px-1 m-1'>
      <div className='w-full flex flex-row'>
        {index+1}. 
      <p className="w-full px-1 mx-1 h-auto border border-black space-x-4 text-white" >{smsPrint.message}
      <button className="text-sm hover:text-base text-black hover:text-gray-900 ml-2">
                        <Link to={`/editMessage/${smsPrint._id}`}>
                        <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </button>
                      <button className="text-sm hover:text-base text-black hover:text-gray-900" onClick={()=>handleDelete(sms._id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                        </p>
      </div>
      <div className="flex flex-row float-right">
                     
                    </div> 
                      </div>
      )
    }
   )}
  {listCamp && sms && sms
  .filter((sms)=>sms.kind === 'camp')
  .map((smsPrint, index) =>{
      return(
      <div className='w-full flex flex-col px-1 m-1'>
      <div className='w-full flex flex-row'>
        {index+1}. 
      <p className="w-full px-1 mx-1 h-auto border border-black space-x-4 text-white" >{smsPrint.message}
      <button className="text-sm hover:text-base text-black hover:text-gray-900 ml-2">
                        <Link to={`/editMessage/${smsPrint._id}`}>
                        <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </button>
                      <button className="text-sm hover:text-base text-black hover:text-gray-900" onClick={()=>handleDelete(sms._id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                       <button  onClick={()=>sendSMS(smsPrint.message, operator)} className="text-sm hover:text-base text-black hover:text-gray-900" title="Send SMS">
                       <FontAwesomeIcon icon={faPaperPlane} />
                      </button> </p>
      </div>
      <div className="flex flex-row float-right">
                     
                    </div>
                   </div>
      )
    }
   )}
   <KindSending sendOperator={takeOperator}/> </div>
  {sms.length === 0 && <p>No message from base..</p>}

    </div>
  )
}

export default SendSMS