import { useEffect } from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Home from './pages/Home.js';
import Login from './pages/Login.js';
import Register from './pages/Register.js';
import Admin from './pages/Admin.js';
import "react-toastify/dist/ReactToastify.css";
import { setUser } from './redux/features/authSlice.js';
import './App.css';
import PrivateRoute from './components/PrivateRoute.js';
import EditClient from './edits/EditClient.js';
import EditSession from './edits/EditSession.js';
import EditArchive from './edits/EditArchive.js';
import PageNotFound from './pages/PageNotFound.js';
import EditCategory from './edits/EditCategory.js';
import EditService from './edits/EditService.js';
import EditMessage from './campains/EditMessage.js';
import EditWorker from './edits/EditWorker.js';

function App() {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('Profile'));
  useEffect(()=>{
    dispatch(setUser(user));
  },[])
  return (
    <BrowserRouter>
    <div>
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/admin' element={<PrivateRoute><Admin /></PrivateRoute> } />
        <Route path="/editClient/:id" element={<PrivateRoute><EditClient /></PrivateRoute> } />
        <Route path="/editSession/:id" element={<PrivateRoute><EditSession /></PrivateRoute> } />
        <Route path="/editCategory/:id" element={<PrivateRoute><EditCategory /></PrivateRoute> } />
        <Route path="/editService/:id" element={<PrivateRoute><EditService /></PrivateRoute> } />
        <Route path="/editMessage/:id" element={<PrivateRoute><EditMessage /></PrivateRoute> } />
        <Route path="/editWorkers/:id" element={<PrivateRoute><EditWorker /></PrivateRoute> } />
        <Route path="*" element={<PageNotFound />} />
      {/*  <Route path="/editArchive/:id" element={<PrivateRoute><EditArchive /></PrivateRoute> } />*/}
      </Routes>
      </div>
      </BrowserRouter>
  );
}

export default App;
