import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { createSMS } from '../redux/features/smsSlice';
import { toast } from 'react-toastify';

const AddMessage = ({closePopup, kind}) => {
    const dispatch = useDispatch();
  const [SMSmessage, setSMSMessage] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
  
    const handleOptionChange = (e) => {
      setSelectedOption(e.target.value);
    };
  const onInputChange = (e) => {
    const { value } = e.target;
    setSMSMessage(value);
}
    const createMessage = (e) => {
        e.preventDefault();
        closePopup(false);

        const updatedData = {message:SMSmessage, kind, selectedOption}
        console.log(updatedData);
        dispatch(createSMS({ updatedData, toast }));
        setSMSMessage('');
      }
  console.log(selectedOption);
  
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
    {/* Zamračena pozadina */}
    <div
      className="absolute inset-0 bg-black opacity-60"
      onClick={() => closePopup(false)} // Klikom na pozadinu zatvaramo modal
    ></div>
    {/* Forma u centru */}
    <div className="relative bg-white p-6 rounded-sm shadow-lg w-11/12 max-w-md mx-auto z-10">
  <button className='text-black font-bold float-right text-md border border-black px-2 mb-2 shadow-md'  
  onClick={() => closePopup(false)}>X</button>

      <form onSubmit={createMessage}>
        <textarea
          placeholder="Create message.."
          value={SMSmessage}
          onChange={onInputChange}
          className="p-2 w-full h-[150px] md:h-[50px] border border-gray-300 rounded-md resize-none"
        />
        {kind === 'auto' && 
       <div className="flex flex-col space-y-2">
       <label className="flex items-center space-x-2">
         <input
           type="radio"
           value="0h"
           checked={selectedOption === '0h'}
           onChange={handleOptionChange}
           className="form-radio text-blue-500"
         />
         <span>Birthday message</span>
       </label>
 
       <label className="flex items-center space-x-2">
         <input
           type="radio"
           value="24h"
           checked={selectedOption === '24h'}
           onChange={handleOptionChange}
           className="form-radio text-blue-500"
         />
         <span>24h earlier</span>
       </label>
 
       <label className="flex items-center space-x-2">
         <input
           type="radio"
           value="48h"
           checked={selectedOption === '48h'}
           onChange={handleOptionChange}
           className="form-radio text-blue-500"
         />
         <span>48h earlier</span>
       </label>
     </div> }
        <button className="text-center bg-black rounded-md text-white font-bold w-full py-2 shadow-md mt-4">
          Create the text of new message
        </button>
      </form>
    </div>
  </div>
  )
}

export default AddMessage