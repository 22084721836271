import React, {useState} from 'react'

const KindSending = ({sendOperator}) => {
    const [selectedOption, setSelectedOption] = useState('');
    
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
      };
      sendOperator(selectedOption)
  return (
    <div className="flex flex-col">
        <h4 className="font-bold">Kind of sending message</h4>
            <div className="flex flex-row text-sm">
       <label className="flex items-center space-x-2">
         <input
           type="radio"
           value="sms"
           checked={selectedOption === 'sms'}
           onChange={handleOptionChange}
           className="form-radio text-black ml-1"
         />
         SMS
       </label>
 
       <label className="flex items-center space-x-2">
         <input
           type="radio"
           value="viber"
           checked={selectedOption === 'viber'}
           onChange={handleOptionChange}
           className="form-radio text-black ml-1"
         />
         Viber
       </label>
 
       <label className="flex items-center space-x-2">
         <input
           type="radio"
           value="whatsup"
           checked={selectedOption === 'whatsup'}
           onChange={handleOptionChange}
           className="form-radio text-black ml-1"
         />
         WhatsUp
       </label>
       <label className="flex items-center space-x-2">
         <input
           type="radio"
           value="wechat"
           checked={selectedOption === 'wechat'}
           onChange={handleOptionChange}
           className="form-radio text-blue-500"
         />
         WeChat
       </label>
    </div>
    </div>
  )
}

export default KindSending