import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as api from '../api.js';

export const createWorker = createAsyncThunk(
    "worker/createWorker",
    async ({workerData, navigate, toast}, {rejectWithValue}) =>{
        try {
            const response = await api.createWorker(workerData);
            toast.success('Worker added successfuly');
            navigate('/admin');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const getUserWorkers = createAsyncThunk(
    "worker/getUserWorkers",
    async ({userId},{rejectWithValue})=>{
        try{
            const response = await api.getUserWorkers(userId);
            return response?.data || { message: "Worker deleted, no response data" };
        }catch(error){
            return rejectWithValue(error.response?.data || { message: "Error occurred" });
        }
    }
)
export const updateWorker = createAsyncThunk(
    "worker/updateWorker",
    async ({id, updatedWorkerData, navigate, toast},{rejectWithValue})=>{
        try{
            const response = await api.updateWorker(id, updatedWorkerData);            
            toast.success('Worker updated successfuly');
            navigate('/admin');
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
export const deleteOneWorker = createAsyncThunk(
    "worker/deleteOneWorker",
    async ({id, toast},{rejectWithValue})=>{
        try{
            const response = await api.deleteOneWorker(id);
            toast.success('Worker deleted successfuly')
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)


const workerSlice = createSlice({ 
    name:'worker',
    initialState:{
        worker:{},
        workers:[],
        userWorkers:[],
        error:"",
        loading:false
    },
   
    extraReducers: (builder) => {
        builder
            .addCase(createWorker.pending, (state) => {
                state.loading = true;
            })
            .addCase(createWorker.fulfilled, (state, action) => {
                state.loading = false;
                state.workers = [...state.workers, action.payload];
                
            })
            .addCase(createWorker.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message 
            })
            .addCase(getUserWorkers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserWorkers.fulfilled, (state, action) => {
                state.loading = false;
                state.workers =  action.payload;
                
            })
            .addCase(getUserWorkers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message 
            })
            .addCase(deleteOneWorker.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteOneWorker.fulfilled, (state, action) => {
                state.loading = false;
                const {arg : {id},} = action.meta;
            if(id){
              state.workers = state.workers.filter((item)=> item._id !== id)
            }
              })
            .addCase(deleteOneWorker.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || "Failed to delete worker";
            })
            .addCase(updateWorker.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateWorker.fulfilled, (state, action) => {
                state.loading = false;
                const {arg : {id},} = action.meta;
                if(id){
                  state.workers = state.workers.map((item)=> item._id === id ? action.payload : item)
                }
            })
            .addCase(updateWorker.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message; 
            })
       
    }
});
export default workerSlice.reducer;