import React, {useState, useEffect} from 'react'
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import { updateService } from '../redux/features/serviceSlice';
import HeaderAdmin from '../components/HeaderAdmin';
import PriceInput from '../components/PriceInput';

const initialState = {
 service:'',
 price:'',
 time:'',
 category:''
}

const EditService = ({goToClients})=> {
    const [serviceData, setServiceData] = useState(initialState);

    const error = useSelector((state) => state.client.error);
    const services = useSelector((state) => state.service.services);
    const categories = useSelector((state) => state.category.categories);

    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();
useEffect(()=>{
    const singleService = services.find((client)=> client._id === id);
    setServiceData({...singleService})
},[])
const setPrice = (price) =>{
  setServiceData({...serviceData,price})
}
    const {service, price, time, category} = serviceData;

    useEffect(()=>{
    error && toast.error(error)
    },[error])
 
      const onInputChange = (e) =>{
        const {name, value} = e.target;
        setServiceData({...serviceData, [name]: value})
      };
      const handleSubmit = (e) =>{
          e.preventDefault();
            const updatedServiceData = {...serviceData, name:user?.result?.name}
            console.log(updatedServiceData);
            dispatch(updateService({id, updatedServiceData, navigate, toast}))  
            handleClear();
          }
      const handleClear = () =>{
        setServiceData({ category:'', service:'', price:'', time:''})
      };

  return (
<div className="min-h-screen flex flex-col bg-gradient-to-br from-green-900 via-green-500 to-green-200 overflow-y-auto">
      {/* Header */}
      <HeaderAdmin />
      <div className="w-full md:p-8 p-4  fixed top-0 left-0 z-10">
        <h2 className="text-3xl font-bold text-white mb-6 mt-[80px]">Edit Service</h2>
        <form onSubmit={handleSubmit} className="space-y-6 w-full">
        <div className="md:w-1/2 w-full">
  <label htmlFor="category" className="block text-sm font-medium text-gray-100">
    Category
  </label>
  <select
    id="category"
    name="category"
    value={category}
    onChange={onInputChange}
    className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none"
  >
    <option value="" disabled>
      Select a category
    </option>
    {categories.map((cat, index) => (
      <option key={index} value={cat.category}>
        {cat.category}
      </option>
    ))}
  </select>
</div>

            <div className="flex flex-col w-full">
         
          <div className='md:w-1/2 w-full '>
            <label htmlFor="service" className="block text-sm font-medium text-gray-100">Service</label>
            <input onChange={onInputChange} value={service} id="service"  name="service" type="text" className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          <div className='md:w-1/2 w-full '>
            <label htmlFor="price" className="block text-sm font-medium text-gray-100">Price</label>
            <PriceInput sendPrice={setPrice} oldPrice={price} />
          </div>
          <div className='md:w-1/2 w-full '>
            <label htmlFor="time" className="block text-sm font-medium text-gray-100">Time</label>
            <input onChange={onInputChange} value={time} id="time"  name="time" type="text" className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
            </div>
          <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
            Submit
          </button>
        </form>
      </div></div>
  )
}

export default EditService;