import React, {useState, useEffect} from 'react'
import PhoneInput from 'react-phone-input-2';
import FileBase from 'react-file-base64';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import 'react-phone-input-2/lib/style.css';
import { createClient } from '../redux/features/clientSlice';
import DateMobile from '../components/DateMobile';

const initialState = {
  firstName:'',
  lastName:'',
  phone:'',
  email:'',
  birth:'',
  address:'',
  description:'',
  liar:'no'
}

const AddClient = ({goToClients})=> {
    const [countryCode, setCountryCode] = useState('');
    const [clientData, setClientData] = useState(initialState);
    const [suggestedPhone, setSuggestedPhone] = useState(""); 
    const [suggestedEmail, setSuggestedEmail] = useState(null);
    const [showClient, setShowClient] = useState(false);
    const [birthInput, setBirthInput] = useState(clientData.birth);

    const error = useSelector((state) => state.client.error);
const loading = useSelector((state) => state.client.loading);
const clients = useSelector((state) => state.client.clients); 
   const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {firstName, lastName, phone, email, birth, address, description} = clientData;

    useEffect(()=>{
    error && toast.error(error)
    },[error])
    const getUserCountryCode = async () => {
        try {
          const response = await fetch('https://ipapi.co/json/');
          const data = await response.json();
          setCountryCode(data.country_code.toLowerCase()); 
        } catch (error) {
          console.error('Error fetching location data:', error);
        }
      };
      useEffect(() => {
        getUserCountryCode();
      }, []);

      const onInputChange = (e) =>{
        const {name, value} = e.target;
        setClientData({...clientData, [name]: value})
      };
      
  const handlePhoneChange = (value) => {
    const formattedValue = `+${value}`;
    setClientData({ ...clientData, phone: formattedValue });

    const matchingPhone = clients.find((client) =>
      client.phone.startsWith(formattedValue)
    );

    setSuggestedPhone(matchingPhone || null); 
  };

  const handlePhoneBlur = () => {
    if (clients.some((client) => client.phone === clientData.phone)) {
      toast.warn("Client exists in data base!");
      
    }
  };
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setClientData({ ...clientData, email: value });
  
    const matchingEmail = clients.find((client) =>
      client.email?.startsWith(value)
    );
  
    setSuggestedEmail(matchingEmail || null); // Postavi predlog mejla ili null
  };
  const takeNewBirth = (newBirthDay) => {
    console.log("New birth received:", newBirthDay); // Loguje novi datum
    setClientData((prevState) => ({
      ...prevState,
      birth: newBirthDay, // Ažurira stanje u clientData
    }));
    setBirthInput(newBirthDay); // Ažurira lokalno stanje
  };
      const handleSubmit = (e) =>{
          e.preventDefault();
          console.log(birthInput, clientData.birth);
    
            const updatedClientData = {...clientData, birth: birthInput, name:user?.result?.email};
            dispatch(createClient({updatedClientData, navigate, toast}));
            goToClients();
            handleClear();
      };
      const handleClear = () =>{
        setClientData({ firstName:'', lastName:'', phone:'', email:'', birth:'', address:'', description:'', liar:'no'})
      };
console.log(suggestedEmail);

  return (
<div>
        <h2 className="text-xl font-bold text-white mb-1">Add Client</h2>
        <form onSubmit={handleSubmit} className="space-y-3 w-full"  noValidate>
            <div className="flex md:flex-row flex-col w-full">
            <div className='md:w-1/3 w-full mt-1'>
            <label htmlFor="phone" className="block text-xs font-medium text-gray-200">Phone Number</label>
       <div style={{ position: "relative" }}>
          <PhoneInput
        country={countryCode} 
        enableSearch={true} 
        value={phone} 
        onChange={handlePhoneChange} // Izmenjeno: Funkcija za promenu
                onBlur={handlePhoneBlur} // Dodato: Funkcija za proveru
      //  onChange={(value) => setClientData({ ...clientData, phone: `+${value }`})} 
        inputStyle={{
            width: '100%',
            padding: '12px',
            borderRadius: '7px',
            border: '1px solid #ccc',
            paddingLeft:'50px',
            marginTop:'100px',
            height:'32px'
          }}
      />
     {suggestedPhone && phone.length > 10 &&  (
    <div
      style={{
        position: "absolute",
        top: "40px",
        left: 0,
        backgroundColor: "#f9f9f9",
        border: "1px solid #ccc",
        padding: "10px",
        zIndex: 100,
      }}
    >
      <p className='text-red-700'>{`Exists client : ${suggestedPhone.phone}`}</p>
     {!showClient && <div className='w-full bg-black text-white text-center p-1 mt-1' onClick={()=>setShowClient(true)}>Client details</div>}
      {showClient && <div>
        <p>
        <strong>FirstName:</strong> {suggestedPhone.firstName}
      </p>
      <p>
        <strong>FirstName:</strong>  {suggestedPhone.lastName}
      </p>
     
      <p>
        <strong>Phone:</strong> {suggestedPhone.phone}
      </p>
      <p>
        <strong>Email:</strong> {suggestedPhone.email}
      </p>
      <div  className='w-full bg-black text-white text-center p-1 mt-1' onClick={()=>setShowClient(false)}>Close details</div></div>}
    
    </div>
  )}
      </div></div>
            <div className='md:w-1/3 w-full'>
            <label htmlFor="firstName" className="block text-xs font-medium text-gray-100">First Name</label>
            <input onChange={onInputChange} id="firstName" name="firstName" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          <div className='md:w-1/3 w-full'>
            <label htmlFor="lastName" className="block text-xs font-medium text-gray-100">Last Name</label>
            <input onChange={onInputChange} id="lastName" name="lastName" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
        
            </div>
            <div className="flex md:flex-row flex-col w-full">
            <div className="md:w-1/3 w-full mt-1">
  <label htmlFor="email" className="block text-xs font-medium text-gray-100">
    Email
  </label>
  <div style={{ position: "relative" }}>
    <input
      onChange={handleEmailChange}
      id="email"
      name="email"
      type="text"
      value={email}
      className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none"
    />
    {suggestedEmail && email.length > 10 && (
      <div
        style={{
          position: "absolute",
          top: "40px",
          left: 0,
          backgroundColor: "#f9f9f9",
          border: "1px solid #ccc",
          padding: "10px",
          zIndex: 100,
        }}
      >
        <p className="text-red-700">{`Exists client: ${suggestedEmail.email}`}</p>
        {!showClient && (
          <div
            className="w-full bg-black text-white text-center p-1 mt-1"
            onClick={() => setShowClient(true)}
          >
            Client details
          </div>
        )}
        {showClient && (
          <div>
            <p>
              <strong>FirstName:</strong> {suggestedEmail.firstName}
            </p>
            <p>
              <strong>LastName:</strong> {suggestedEmail.lastName}
            </p>
            <p>
              <strong>Phone:</strong> {suggestedEmail.phone}
            </p>
            <p>
              <strong>Email:</strong> {suggestedEmail.email}
            </p>
            <div
              className="w-full bg-black text-white text-center p-1 mt-1"
              onClick={() => setShowClient(false)}
            >
              Close details
            </div>
          </div>
        )}
      </div>
    )}
  </div>
</div>

<div className='md:w-1/3 w-full mt-1'>
             <label htmlFor="birth" className="block text-xs font-medium text-gray-100">Date of birth</label>
<DateMobile  birthDay={birth} forwardNewBirth={takeNewBirth} />
 </div>
<div className='md:w-1/3 w-full mt-1'>
            <label htmlFor="address" className="block text-xs font-medium text-gray-100">Address</label>
            <input onChange={onInputChange} id="address" name="address" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
</div>
</div>
<div className="flex md:flex-row flex-col w-full">
           <div className='md:w-2/3 w-full mt-1'>
             <label htmlFor="description" className="block text-xs font-medium text-gray-100">Description</label>
             <textarea onChange={onInputChange} id="description" name="description" type="text" className="mt-1 w-full h-[100px] px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
 </div>
 <div className='md:w-1/3 mt-1'>
    <label htmlFor="liar" className="block text-xs font-medium text-gray-100">Promise-breaker</label>
    <select 
        id="liar" 
        name="liar" 
        value={clientData.liar} 
        onChange={(e) => setClientData({ ...clientData, liar: e.target.value })} 
        className="ml-3 w-1/3 mt-1 px-2 py-1 border border-gray-300 bg-white rounded-md shadow-xs focus:outline-none focus:ring-2 focus:ring-green-400 focus:border-transparent"
    >
        <option value="no">No</option>
        <option value="yes">Yes</option>
    </select>
</div>
 </div>
       <button type="submit" className="w-full flex justify-center py-1 px-2 border border-transparent rounded-md shadow-xs text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
       Submit
     </button>
        </form>
      </div>
  )
}

export default AddClient;