import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Serbian } from "flatpickr/dist/l10n/sr.js";

const DateMobile = ({ birthDay, forwardNewBirth }) => {
  const [newDate, setNewDate] = useState();

  const formattedDate = new Date(birthDay).toLocaleDateString("sr-RS", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  useEffect(() => {
    if (newDate) {
      forwardNewBirth(newDate); // Šalje ažurirani datum parent komponenti
    }
  }, [newDate]);

  const formatLocalDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <Flatpickr
      id="birth"
      className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none"
      placeholder={formattedDate}
      options={{
        dateFormat: "d.m.Y",
        locale: Serbian,
        disableMobile: true,
        allowInput: true,
      }}
      onChange={(selectedDates) => {
        if (selectedDates.length > 0) {
          const date = selectedDates[0];
          const formattedDate = formatLocalDate(date);
          setNewDate(formattedDate);
        }
      }}
    />
  );
};

export default DateMobile;
