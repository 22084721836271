import React, {useState} from 'react';
import WorkerData from './WorkerData';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';
import { deleteOneWorker } from '../redux/features/workerSlice';
import { toast } from 'react-toastify';
const Workers = ({workers}) => {
    const [showData, setShowData] = useState(false);
    const [workerData, setWorkerData] = useState();
    const dispatch = useDispatch();
    const openShowData = (id)=>{
        const selectedWorker = workers.find(worker => worker._id === id);
        setWorkerData(selectedWorker); 
        setShowData(true); 
      }
      const handleDelete = (id) =>{
        if(window.confirm('Are you sure you want to delete this worker?',id)){
            dispatch(deleteOneWorker({id, toast}))
        }
          }
  return (
    <div>
    <ul className="flex flex-col md:w-1/2">
    {workers.map((item, index) => (
      <li
        key={item._id}
        className="flex flex-row justify-between font-bold border border-black"
      >
        {index + 1}. {item.firstName} {item.lastName}
        <div className="flex flex-row">
          <button className="text-sm hover:text-base hover:text-gray-900 mx-1">
            <Link to={`/editWorkers/${item._id}`}>
              <FontAwesomeIcon icon={faEdit} />
            </Link>
          </button>
          <button
            className="text-sm hover:text-base hover:text-gray-900 mx-1"
            onClick={() => handleDelete(item._id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            className="text-sm hover:text-base hover:text-gray-900"
            onClick={() => openShowData(item._id)}
          >
            <i>View..</i>
          </button>
        </div>
      </li>
    ))}
  </ul>
  {showData && (
    <WorkerData workerData={workerData} closePopup={() => setShowData(false)}/>
    )}</div>
  )
}

export default Workers